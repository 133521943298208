import React,{ useState,useEffect } from "react"
import Slider from "react-slick";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import he from 'he';

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const LatestNewsInsights = ({ insightColumn,latestNewsColumn }) => {

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    arrows: false,
    // customPaging: function(i) {
    //   return (
    //     <span></span>
    //   );
    // },
    responsive: [
      {
        breakpoint: 980,
        settings: {
          variableWidth: false,
          centerMode: false
        }
      }
    ]
  };

const breakpoints = useBreakpoint();

const [showSecondInsights, setShowSecondInsights] = useState({exampleArr:[]});
const [showFirstInsight, setShowFirstInsight] = useState({exampleArr:[]});
const [showSecondNews, setShowSecondNews] = useState({exampleArr:[]});
const [showFirstNews, setShowFirstNews] = useState({exampleArr:[]});

const newsItems = []
const insightItems = []

  let newsDetails = latestNewsColumn.firstNewsPost.map(function(item) {
    if(item.posts.nodes.length) {
      newsItems.push({
        value: item.posts.nodes[0].title,
      })
    }
    return item;

  });
  let insightDetails = insightColumn.firstInsightPost.map(function(item) {
    if(item.posts.nodes.length) {
      insightItems.push({
        value: item.posts.nodes[0].title,
      })
    }
    return item;
  });

const totalItems=newsItems.length+insightItems.length;

const updatePosts=(e)=>{
// insightItems.pop();

  //if news is 0
  //show insight 2
  //show no news

  //if news is 1
  //show 1 insight
  //show 1 news




  //if no news items then show both insights
  if(newsItems.length===0){

    if (insightItems.length==1){
      setShowFirstInsight({exampleArr: ["item"]})
    }
    else if(insightItems.length==2){
      setShowFirstInsight({exampleArr: ["item"]})
      setShowSecondInsights({exampleArr: ["item"]})
    }

  }
  else if(newsItems.length===1){
    setShowFirstNews({exampleArr: ["item"]})
    if (insightItems.length==1){
      setShowFirstInsight({exampleArr: ["item"]})
    }

  }

  else if(newsItems.length===2){
    setShowFirstNews({exampleArr: ["item"]})
    setShowSecondNews({exampleArr: ["item"]})
  }
}

const decodeString = (string) => {
  console.log('supplied string', string); 
  let decodedString = he.decode(string);
  console.log('decoded string', decodedString); 
  return decodedString;
}

useEffect(() => {
  updatePosts()
}, []);


  return (
    <>
 {breakpoints.sm ?
    <section className="content very-light-blue-background">
<div className="eighty-spacer"></div>
<div className="content-block">
  <div className="centered-content-container">
    <div className="centered-title-holder">
        <span>
        Latest news
        </span>
    </div>
  </div>
<div className="eighty-spacer"></div>

<Slider className="one-flex-con tainer news-insight news-insight-mob-slider mobs" {...slickSettings}>
{showFirstNews.exampleArr.length ? (
<div className="half-flex-container mobs">
    <a href={`/blog/${latestNewsColumn.firstNewsPost[0].posts.nodes[0].slug}`}>
      <div className="full-width-flex-container slick-flex-mob">
        <div className="full-width-news-content">
        <h6>{decodeString(latestNewsColumn.firstNewsPost[0].posts.nodes[0].title)} foo</h6>
        <div className="full-width-news-content-container">
        {latestNewsColumn.firstNewsPost[0].posts.nodes[0].customNewsDetails.customSubHeading ? (
          <span className="full-width-news-content-container" dangerouslySetInnerHTML={{ __html: latestNewsColumn.firstNewsPost[0].posts.nodes[0].customNewsDetails.customSubHeading }}/>
          ) : null}
          <div className="news-full-link">
                Find out more
            </div>

    </div>
  </div>
  </div>
  </a>
 </div>
)
: null}
{showSecondNews.exampleArr.length ? (
  <div className="half-flex-container mobs">
      <a href={`/blog/${latestNewsColumn.firstNewsPost[1].posts.nodes[0].slug}`}>
        <div className="full-width-flex-container slick-flex-mob">
          <div className="full-width-news-content">
          <h6>{decodeString(latestNewsColumn.firstNewsPost[1].posts.nodes[0].title)}</h6>
          <div className="full-width-news-content-container">
          {latestNewsColumn.firstNewsPost[1].posts.nodes[0].customNewsDetails.customSubHeading ? (
<span className="full-width-news-content-container" dangerouslySetInnerHTML={{ __html: latestNewsColumn.firstNewsPost[1].posts.nodes[0].customNewsDetails.customSubHeading }}/>
            ) : null}
            <div className="news-full-link">
                  Find out more
              </div>
      </div>
    </div>
    </div>
    </a>
   </div>
)
: null}
{showFirstInsight.exampleArr.length ? (
  <div className="half-flex-container">
  <a href={`/blog/${insightColumn.firstInsightPost[0].posts.nodes[0].slug}`}>
    <div className="full-width-flex-container slick-flex-mob">
          <div className="full-width-news-content">
                  <h6>{decodeString(insightColumn.firstInsightPost[0].posts.nodes[0].title)}</h6>
                  <div className="full-width-news-content-container">
                  {insightColumn.firstInsightPost[0].posts.nodes[0].customNewsDetails.customSubHeading ? (
      <span className="full-width-news-content-container" dangerouslySetInnerHTML={{ __html: insightColumn.firstInsightPost[0].posts.nodes[0].customNewsDetails.customSubHeading }}/>
                    ) : null}
                  </div>
                  <div className="news-full-link">
                  Find out more
                  </div>
          </div>
    </div>
  </a>
  </div>
)
: null}
{showSecondInsights.exampleArr.length ? (
  <div className="half-flex-container">
  <a href={`/blog/${insightColumn.firstInsightPost[1].posts.nodes[0].slug}`}>
    <div className="full-width-flex-container slick-flex-mob">
          <div className="full-width-news-content">
                  <h6>{decodeString(insightColumn.firstInsightPost[1].posts.nodes[0].title)}</h6>
                  <div className="full-width-news-content-container">
                  {insightColumn.firstInsightPost[1].posts.nodes[0].customNewsDetails.customSubHeading ? (
      <span className="full-width-news-content-container" dangerouslySetInnerHTML={{ __html: insightColumn.firstInsightPost[1].posts.nodes[0].customNewsDetails.customSubHeading }}/>
                    ) : null}
                  </div>
                  <div className="news-full-link">
                  Find out more
                  </div>
          </div>
    </div>
  </a>
  </div>
)
: null}
</Slider>

</div>
<div className="eighty-spacer"></div>
</section>
 :     <section className="content very-light-blue-background desktop">
                   <div className="eighty-spacer"></div>
     <div className="content-block">
      <div className="centered-content-container">
         <div className="centered-title-holder">
            <span>
               Latest news
            </span>
         </div>
      </div>
      <div className="eighty-spacer"></div>
     <div className="one-flex-container news-insight news-insight-mob-slider">
     {showFirstNews.exampleArr.length ? (
       <>
     <div className="half-flex-container">

         <a href={`/blog/${latestNewsColumn.firstNewsPost[0].posts.nodes[0].slug}`}>
           <div className="full-width-flex-container slick-flex-mob">
             <div className="full-width-news-content">
             <h6>{decodeString(latestNewsColumn.firstNewsPost[0].posts.nodes[0].title)}</h6>
             <div className="full-width-news-content-container">
             {latestNewsColumn.firstNewsPost[0].posts.nodes[0].customNewsDetails.customSubHeading ? (
 <span className="full-width-news-content-container" dangerouslySetInnerHTML={{ __html: latestNewsColumn.firstNewsPost[0].posts.nodes[0].customNewsDetails.customSubHeading }}/>
               ) : null}
               <div className="news-full-link">
                     Find out more
                 </div>

         </div>
       </div>
       </div>
       </a>
      </div>
     </>
     )
   : null}
   {showSecondNews.exampleArr.length ? (
     <>
     <div className="half-flex-container">

         <a href={`/blog/${latestNewsColumn.firstNewsPost[1].posts.nodes[0].slug}`}>
           <div className="full-width-flex-container slick-flex-mob">
             <div className="full-width-news-content">
             <h6>{decodeString(latestNewsColumn.firstNewsPost[1].posts.nodes[0].title)}</h6>
             <div className="full-width-news-content-container">
             {latestNewsColumn.firstNewsPost[1].posts.nodes[0].customNewsDetails.customSubHeading ? (
   <span className="full-width-news-content-container" dangerouslySetInnerHTML={{ __html: latestNewsColumn.firstNewsPost[1].posts.nodes[0].customNewsDetails.customSubHeading }}/>
               ) : null}
               <div className="news-full-link">
                     Find out more
                 </div>

         </div>
       </div>
       </div>
       </a>
      </div>
   </>
   )
 : null}
     {showFirstInsight.exampleArr.length ? (
       <>

     <div className="half-flex-container">
         <a href={`/blog/${insightColumn.firstInsightPost[0].posts.nodes[0].slug}`}>
           <div className="full-width-flex-container slick-flex-mob">
             <div className="full-width-news-content">
             <h6>{decodeString(insightColumn.firstInsightPost[0].posts.nodes[0].title)}</h6>
             <div className="full-width-news-content-container">
             {insightColumn.firstInsightPost[0].posts.nodes[0].customNewsDetails.customSubHeading ? (
 <span className="full-width-news-content-container" dangerouslySetInnerHTML={{ __html: insightColumn.firstInsightPost[0].posts.nodes[0].customNewsDetails.customSubHeading }}/>
               ) : null}
               <div className="news-full-link">
                     Find out more
                </div>
         </div>
       </div>
       </div>
       </a>
      </div>
     </>
     )
   : null}
   {showSecondInsights.exampleArr.length ? (
     <>
     <div className="half-flex-container">
         <a href={`/blog/${insightColumn.firstInsightPost[1].posts.nodes[0].slug}`}>
           <div className="full-width-flex-container slick-flex-mob">
             <div className="full-width-news-content">
             <h6>{decodeString(insightColumn.firstInsightPost[1].posts.nodes[0].title)}</h6>
             <div className="full-width-news-content-container">
             {insightColumn.firstInsightPost[1].posts.nodes[0].customNewsDetails.customSubHeading ? (
   <span className="full-width-news-content-container" dangerouslySetInnerHTML={{ __html: insightColumn.firstInsightPost[1].posts.nodes[0].customNewsDetails.customSubHeading }}/>
               ) : null}
               <div className="news-full-link">
                     Find out more
                 </div>

         </div>
       </div>
       </div>
       </a>
      </div>
   </>
   )
 : null}
     </div>
     </div>
     <div className="eighty-spacer"></div>

     </section>}



      </>
  )
}

export default LatestNewsInsights
